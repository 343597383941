<template>
	<nav id="main-right-nav">
		<UserMenu v-if="isAuthenticated" />
	</nav>
</template>

<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue';

import UserMenu from '@/components/navigations/UserMenu.vue';

const { isAuthenticated } = useAuth0();
</script>
