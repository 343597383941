<template>
	<nav id="main-left-nav">
		<router-link class="logo conexus-logo" :to="{ name: RouteName.Root }">
			<span class="sr-only">Root page</span>
			<UISvgIcon name="conexus-full" />
		</router-link>
		<ul>
			<li>
				<router-link class="link" :to="{ name: RouteName.Ratings }">
					<UISvgIcon name="analytics" />
					<span>Ratings</span>
				</router-link>
			</li>
			<li>
				<router-link class="link" :to="{ name: RouteName.Graphs }">
					<UISvgIcon name="campaign" />
					<span>Graphs</span>
				</router-link>
			</li>
			<li>
				<router-link class="link" :to="{ name: RouteName.DisplayCohort }">
					<UISvgIcon name="dashboard" />
					<span>Display Cohorts</span>
				</router-link>
			</li>
			<li>
				<router-link class="link" :to="nonExistingPath">
					<UISvgIcon name="alert" />
					<span>Error</span>
				</router-link>
			</li>
		</ul>
		<ul class="help">
			<li>
				<a
					href="https://edge-styleguide.invidi.io/"
					target="_blank"
					rel="noreferrer noopener"
					class="link"
				>
					<UISvgIcon name="help-contextual" />
					<span>Styleguide docs</span>
				</a>
			</li>
		</ul>
	</nav>
</template>

<script setup lang="ts">
import { RouteName } from '@/routes/routeNames';

const nonExistingPath = '/some-non-existing-page';
</script>
