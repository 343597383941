import { createAuth0 } from '@auth0/auth0-vue';
import Log, { LogSeverity, OutputType } from '@invidi/common-edge-logger-ui';
import {
	UISvgIcon,
	UIToastType,
	useUIToastsStore,
} from '@invidi/conexus-component-library-vue';
import * as Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from '@/App.vue';
import { AppConfig, setInitialConfig } from '@/globals/config';
import { setLogger } from '@/log';
import { createRouter } from '@/router';

async function runApp(): Promise<void> {
	const configJsonResponse = await fetch('/config.json');
	const configJson: Record<string, any> = await configJsonResponse.json();

	// Config
	const cfg = new AppConfig({
		appBasePath: configJson.APP_BASE_PATH,
		auth0Config: {
			audience: configJson.AUTH0_AUDIENCE,
			clientId: configJson.AUTH0_CLIENT_ID,
			domain: configJson.AUTH0_DOMAIN,
			redirectUri: configJson.AUTH0_REDIRECT_URI ?? window.location.origin,
		},
		currency: 'USD',
		dateFormat: 'yyyy-MM-dd',
		dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
		locale: 'en-US',
		logColors: configJson.LOG_COLORS,
		logLevel: configJson.LOG_MIN_LEVEL || 'NOTICE',
		logOutputType: configJson.LOG_OUTPUT_TYPE === 'json' ? 'json' : 'string',
	});

	// Logger
	const log = new Log({
		colors: cfg.logColors,
		minLogLevel: cfg.logLevel as LogSeverity,
		outputType: cfg.logOutputType as OutputType,
	});

	log.info('App version', { appVersion: configJson.APP_VERSION });
	log.info('App config', cfg as any as Record<string, string>);

	setLogger(log);

	// Router
	const router = createRouter({ log });

	setInitialConfig(cfg, log);

	log.notice('Rendering main application', { logLocation: 'src/index.tsx' });

	const pinia = createPinia();
	const app = createApp(App);
	const auth0 = createAuth0({
		domain: cfg.auth0Config.domain,
		clientId: cfg.auth0Config.clientId,
		authorizationParams: {
			audience: cfg.auth0Config.audience,
			redirect_uri: cfg.auth0Config.redirectUri,
		},
	});

	app.component('UISvgIcon', UISvgIcon);
	app.use(router);
	app.use(pinia);
	app.use(auth0);
	app.use(HighchartsVue, { highcharts: Highcharts });
	app.mount('#root');

	// If an error occurs in an async await initialized in a setup function
	// an unhandled promise rejection will occur. There is no way to handle this
	// except having this type of listener on the window object.
	// This is a last resort as of now. The way we need to handle errors
	// is to do it within the components (until we can use <suspense>)
	window.onunhandledrejection = (event): void => {
		const toastsStore = useUIToastsStore();

		log.error('Unhandled promise rejection occured', {
			err: event.reason?.message,
			logLocation: 'index.ts - onunhandledrejection',
		});

		toastsStore.add({
			body: String(event.reason?.message),
			title: 'Unexpected error',
			type: UIToastType.ERROR,
		});
	};
}

runApp();
